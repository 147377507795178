<template>
  <div id="AppContainer">
    <RouterView />
    <UIToast />
    <TheAssistantChat
      v-if="userStore.isLoggedIn && hasFeature(FeatureFlag.aiAssistant)"
    />

    <AppVersion />
  </div>
</template>

<script setup lang="ts">
import UIToast from './views/components/ui/toasts/UIToast.vue'
import { LOCALES } from './i18n'
import { useOrgStore } from './store/org'
import { useUserStore } from './store/user'
import { useCompanyStore } from './store/company'
import { toRefs, watch } from 'vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useLocale } from './composables/useLocale'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { useFeatureFlagStore } from './store/featureFlag'
import TheAssistantChat from './views/components/ui/chats/TheAssistantChat/TheAssistantChat.vue'
import AppVersion from './views/components/app/AppVersion.vue'

const orgStore = useOrgStore()
const userStore = useUserStore()
const { companies } = toRefs(useCompanyStore())
const { hasFeature } = useFeatureFlagStore()

const route = useRoute()

const userCompanyLocale = computed(
  () =>
    companies.value.find(
      ({ id }) => userStore.user?.companyId && id === userStore.user?.companyId,
    )?.locale,
)

const { getLocale, setLocale } = useLocale()

watch(
  () => [orgStore.org?.locale, userStore.user?.locale, userCompanyLocale.value],
  ([orgLocale, userLocale, companyLocale]) => {
    const storedLocale = getLocale()
    setLocale(
      storedLocale ||
        userLocale ||
        companyLocale ||
        orgLocale ||
        (route?.query.language as LOCALES) ||
        LOCALES.EN,
    )
  },
  { immediate: true },
)
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  box-sizing: inherit;

  &:before,
  &:after {
    background-repeat: no-repeat;
    box-sizing: inherit;
  }
}

html {
  line-height: 1.5;
  font-family: $font-roboto, sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  -moz-tab-size: 4;
  tab-size: 4;
  box-sizing: border-box;
}

button {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  appearance: none;
}

body {
  margin: 0;
  --ck-z-default: 2000;
  --ck-z-panel: calc(var(--ck-z-default) + 999);
}

#AppContainer {
  display: flex;
  min-height: 100vh;
  font-family: $font-roboto;
  color: $newcolor-secondary-main;
  // background-color: $newcolor-grey-50;
  background-color: $color-white;
}
</style>
