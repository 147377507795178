import { IEsgParagraph, IEsgRequirement } from '@/apiClient/types/esg'
import { useLogger } from '@/composables/useLogger'

const fieldComputedVariableRegex = /\{\{([\w-]*?)(\[([0-9]*?)\])?\}\}/g

const logger = useLogger('AnswerDrawer.utils')

export const computeField = (
  field: string | boolean,
  esgRequirementByResourceId: Record<string, IEsgRequirement>,
  injectedFormValues?: Record<string, any>,
  parentInputName?: string,
) => {
  try {
    logger.debug(`[computeField] field`, field)
    logger.debug(`[computeField] injectedFormValues`, injectedFormValues)

    if (typeof field !== 'string') return field

    if (!injectedFormValues) return true

    const matches = [...(field as string).matchAll(fieldComputedVariableRegex)]
    let result = field as string

    for (const [
      match,
      requirementResourceId,
      _,
      answerConfigIndex,
    ] of matches) {
      let value = 'false'

      if (requirementResourceId === 'parentAnswerConfig') {
        const fieldPath = parentInputName?.endsWith('.value')
          ? parentInputName
          : `${parentInputName}.value`
        value = String(
          fieldPath
            .split('.')
            .reduce((accum, path) => accum?.[path], injectedFormValues) ||
            'false',
        )
      } else {
        const requirement = esgRequirementByResourceId[requirementResourceId]

        if (!requirement) {
          logger.error(
            `[computeField] Requirement '${requirementResourceId}' cannot be found.`,
          )
        } else {
          const answerConfigId =
            requirement.answerConfig?.[Number(answerConfigIndex)]?.id
          value = answerConfigId
            ? injectedFormValues[answerConfigId]?.value ||
              injectedFormValues?.[requirement.id]?.[answerConfigId]?.value
            : 'false'

          const wrapWithString =
            value &&
            !['NUMBER', 'SWITCH', 'BOOLEAN'].includes(
              requirement.answerConfig?.[Number(answerConfigIndex)]?.type || '',
            )

          if (wrapWithString) {
            value = `\`${value}\``
          }
        }
      }

      result = result.replaceAll(match, value)
    }

    result = result.replace(/'`|`'|"`|`"/g, '`')

    logger.debug(`[computeField] result`, result)
    const evaluatedResult = new Function(`return ${result}`)()

    return evaluatedResult
  } catch (err) {
    logger.error(`[computeField]`, err)
    return false
  }
}

export const getTooltipWithParagraphsHtml = (config: {
  paragraphs?: IEsgParagraph[]
  tooltip?: string
}) => {
  const tooltipArray = []
  const tooltip = config.tooltip || ''

  if (tooltip) {
    tooltipArray.push(tooltip)
  }

  if (tooltip && config?.paragraphs?.length) {
    tooltipArray.push('<br/>')
  }

  if (config?.paragraphs?.length) {
    tooltipArray.push(`<strong>Paragraphs:</strong> ${config.paragraphs
      .map(({ esgStandard, resourceId }) => `${esgStandard}: ${resourceId}`)
      .join(', ')}
    `)
  }

  return tooltipArray.join('')
}
